import { useEffect } from 'react';

const useChatwoot = () => {
    useEffect(() => {
        const BASE_URL = "https://chat.locroi.net/";
        const script = document.createElement('script');
        script.src = 'https://chat.locroi.net/packs/js/sdk.js?v=v0.0.1-474a061442c3df35f8c5f8f525e5e4175cf9b120';
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            (window as any).chatwootSDK.run({
                websiteToken: 'DyZs8UuRVtAKi5uhigCijPtv',
                baseUrl: BASE_URL,
                userToken: ''
            });
        };

        const handleChatbotLoaded = (e: Event) => {
            console.log('widget Loaded');
            (window as any).$chatwoot.setLocale('vi');
        };

        window.addEventListener('chatbot:loaded', handleChatbotLoaded);

        return () => {
            window.removeEventListener('chatbot:loaded', handleChatbotLoaded);
        };
    }, []);
};

export default useChatwoot;